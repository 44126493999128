import React, { useContext } from 'react'
import { Link } from 'gatsby'
import reduce from 'lodash/reduce'
import parse from "html-react-parser"
import Logo from "./common_logo_wh.svg";
import Arrow from "~/images/common_icon_arrow_back.svg";
import Account from "./common_icon_account.svg";
import * as containerStyles from './nav.module.scss'
import SnsFollow from "~/components/modules/snsfollow"

const Header = ({ siteTitle, isHomePage }) => {
  const gnavClose =(e)=> {
    if(document.getElementById("smt-menu-css").checked) {
      document.getElementById("smt-menu-css").checked = false;
    }
  }

  return (

    <>

    <header className={containerStyles.header}>
      <div className={containerStyles.headerLogo + " headerLogo"}>
      {isHomePage ? (
        <h1>
          <Link to="/">
            <div role="img" aria-label={parse(siteTitle)}>
              <Logo />
            </div>
          </Link>
        </h1>
      ) : (
        <Link className="header-link-home" to="/">
          <div role="img" aria-label={parse(siteTitle)}>
            <Logo />
          </div>
        </Link>
      )}
      </div>
      <input type="checkbox" id="smt-menu-css" className={containerStyles.onoff + " hidden"} />
      <label id="smtGnaviBtn" htmlFor="smt-menu-css" className={containerStyles.smtGnaviBtn + " visible-phone"}>
        <span className={containerStyles.menuicon + " menuicon"}>
          <span className={containerStyles.top}></span>
          <span className={containerStyles.middle}></span>
          <span className={containerStyles.bottom}></span>
        </span>
      </label>
      <nav className={containerStyles.gnav}>
        <ul className={containerStyles.gnavMain + " gnavMain heading english"}>
          <li>
            <Link to="/#topPlan" onClick={gnavClose}><span className="visible-phone">料金プラン</span><span>Plan</span></Link>
          </li>
          <li>
            <Link to="/blog/" onClick={gnavClose}><span className="visible-phone">ブログ</span><span>Blog</span></Link>
          </li>
          <li>
            <Link to="/pages/faq/" onClick={gnavClose}><span className="visible-phone">よくあるご質問</span><span>FAQ</span></Link>
          </li>
          <li>
            <Link to="/contact/" onClick={gnavClose}><span className="visible-phone">お問い合わせ</span><span>Contact</span></Link>
          </li>
          <li className={containerStyles.gnavAccount}>
            <a href="https://shpree-snish.myshopify.com/account/" onClick={gnavClose}><span><Account /></span></a>
          </li>
          <li className={containerStyles.gnavBack}>
            <Link to="/" onClick={gnavClose}><Arrow />Back to Top</Link>
          </li>
          <li className={containerStyles.gnavRequest}>
            <Link to="/order/" onClick={gnavClose}><span>お申し込み</span></Link>
          </li>
        </ul>
        <div className={containerStyles.gnavFooter}>
          <div className="visible-phone">
            <ul className={containerStyles.gnavSub}>
              <SnsFollow />
            </ul>
          </div>
        </div>
      </nav>
      <nav className={containerStyles.accountNav + " visible-phone"}>
        <ul>
          <li className={containerStyles.gnavAccount}>
            <a href="https://shpree-snish.myshopify.com/account/" onClick={gnavClose}><span><Account /></span></a>
          </li>
        </ul>
      </nav>
    </header>
    </>
  )
}

export default Header
