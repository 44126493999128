import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import LineIcon from "~/images/common_icon_line.svg"

const SnsFollow = () => {
  return (
    <>
    <li className="shareIconsTwitter">
      <a href="https://twitter.com/shpreejp" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </li>
    <li className="shareIconsInstagram">
      <a href="https://www.instagram.com/shpree.japan" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </li>
    <li className="shareIconsFacebook">
      <a href="https://www.facebook.com/shpree.jp" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
    </li>
    <li className="shareIconsLine">
      <a href="https://line.me/R/ti/p/%40638iacxo" target="_blank" rel="noopener noreferrer">
        <LineIcon />
      </a>
    </li>
    </>
  )
}

export default SnsFollow
