// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-deliver-js": () => import("./../../../src/pages/contact-deliver.js" /* webpackChunkName: "component---src-pages-contact-deliver-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-order-js": () => import("./../../../src/pages/contact-order.js" /* webpackChunkName: "component---src-pages-contact-order-js" */),
  "component---src-pages-contact-other-js": () => import("./../../../src/pages/contact-other.js" /* webpackChunkName: "component---src-pages-contact-other-js" */),
  "component---src-pages-contact-repair-js": () => import("./../../../src/pages/contact-repair.js" /* webpackChunkName: "component---src-pages-contact-repair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-templates-blog-category-archive-js": () => import("./../../../src/templates/blog-category-archive.js" /* webpackChunkName: "component---src-templates-blog-category-archive-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-archive-js": () => import("./../../../src/templates/blog-tag-archive.js" /* webpackChunkName: "component---src-templates-blog-tag-archive-js" */)
}

