import React from 'react'
import { Link } from 'gatsby'
import SnsFollow from "~/components/modules/snsfollow"

import Logo from "./common_logo_wh.svg"
import LineIcon from "~/images/common_icon_line.svg"
import MailIcon from "~/images/common_icon_mail.svg"
import * as containerStyles from './nav.module.scss'

const Footer = ({ siteTitle }) => (
  <footer className={containerStyles.footer}>
    <div>
      <div role="img" aria-label="Shpree" className={containerStyles.footerLogo}>
        <Logo />
      </div>
      <div className="shareIcons">
        <ul className={containerStyles.footerSns}>
          <SnsFollow />
        </ul>
      </div>
      <ul className={containerStyles.footerBtnWrap}>
        <li>
          <a href="/contact/" target="_blank" rel="noopener noreferrer" className="btn is-primary">
            <span><MailIcon /></span>
            <span>お問い合わせ</span>
          </a>
        </li>
        <li>
          <a href="https://line.me/R/ti/p/%40638iacxo" target="_blank" rel="noopener noreferrer" className="btn is-primary">
            <span><LineIcon /></span>
            <span>LINEで相談する</span>
          </a>
        </li>
      </ul>
      <nav className={containerStyles.footerGnav}>
        <ul>
          <li>
            <a href="https://shpree-snish.myshopify.com/pages/company">会社概要</a>
          </li>
          <li>
            <a href="https://shpree-snish.myshopify.com/policies/terms-of-service">ご利用規約</a>
          </li>
          <li>
            <a href="https://shpree-snish.myshopify.com/policies/legal-notice">特定取引法</a>
          </li>
          <li>
            <a href="https://shpree-snish.myshopify.com/pages/compensation">賠償基準</a>
          </li>
          <li>
            <a href="https://shpree-snish.myshopify.com/policies/privacy-policy">個人情報保護方針</a>
          </li>
          <li>
            <Link to="/contact">お問い合わせ</Link>
          </li>
          <li>
            <Link to="/blog">メディア掲載事例</Link>
          </li>
        </ul>
      </nav>
      <aside className={containerStyles.footerAside}>
        <small>copyright &copy; shpree.inc All Rights Reserved</small>
      </aside>
    </div>
  </footer>
)

export default Footer
