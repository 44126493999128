// custom typefaces
// normalize CSS across browsers
// custom CSS styless
require('./src/normalize.css')
require('./src/container.scss')

function acordionToggle() {
  console.log("実行");
  const toggleInner = document.querySelectorAll(".acordionSwitch");
  let nextdd;
  let nextddHeight;
  let nextddH = [];

  let toggleLoop = (i) => {

      nextddH[i] = toggleInner[i].nextElementSibling.clientHeight;
      toggleInner[i].nextElementSibling.style.height = '0px';

      toggleInner[i].addEventListener('click', () => {
          toggleInner[i].classList.toggle('is-open');
          nextdd = toggleInner[i].nextElementSibling;
          nextddHeight = nextdd.firstElementChild.clientHeight;
          console.log(i + nextddHeight);

          if (nextdd.style.height !== '0px') {
              nextdd.style.height = '0px';
          } else {
              nextdd.style.height = nextddHeight + 'px';
          }

      }, false);

  };

  for (let i = 0; i < toggleInner.length; i++) {
   toggleLoop(i);
  }
}

function setDetailsHeight(selector, wrapper = document) {
  const setHeight = (detail, open = false) => {
    detail.open = open;
    const rect = detail.getBoundingClientRect();
    detail.dataset.width = rect.width;
    detail.style.setProperty(open ? `--expanded` : `--collapsed`,`${rect.height}px`);
  }
  const details = wrapper.querySelectorAll(selector);
  const RO = new ResizeObserver(entries => {
    return entries.forEach(entry => {
      const detail = entry.target;
      const width = parseInt(detail.dataset.width, 10);
      if (width !== entry.contentRect.width) {
        detail.removeAttribute('style');
        setHeight(detail);
        setHeight(detail, true);
        detail.open = false;
      }
    })
  });
  details.forEach(detail => {
    RO.observe(detail);
  });
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  setDetailsHeight('details');
  acordionToggle();
  if ("/" === location.pathname) {
    document.querySelector('body').classList.add("home")
  } else {
    document.querySelector('body').classList.remove("home")
  }
}

exports.onInitialClientRender = () => {
  console.log("We've started!")
  console.log("申し込みページ");
}
